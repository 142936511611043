body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  font-size: 16px;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-logo {
  margin-top: 2rem;
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

form {
  padding: 2rem;
  display: inline-grid;
  align-self: center;
  grid-gap: 8px;
  grid-template-columns: minmax(200px, 300px) minmax(200px, 300px) 100px;
}

@media only screen and (max-width: 500px) {
  form {
    grid-template-columns: unset;
  }
}

input {
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  padding: 8px;
  font-size: 16px;
}

button {
  background-color: green;
  outline: none;
  border-radius: 4px;
  border: none;
  color: white;
  min-height: 28px;
}

table {
  margin: 0 16px;
  text-align: left;
  border-collapse: collapse;
}

tr:nth-of-type(2n+1) {
  border-bottom: 1px solid #ccc;
}

